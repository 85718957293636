<template>
    <div>
        <el-card shadow="never">
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>品牌列表</span>
                </div>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="8" style="text-align:left;">
                            <div class="searchblock">
                                <el-select v-model="status" style="margin-right:10px;" size="small">
                                    <el-option label="全部" value="0"></el-option>
                                    <el-option label="启用" value="1"></el-option>
                                    <el-option label="停用" value="2"></el-option>
                                </el-select>
                                <el-input v-model="keyword" placeholder="品牌名称" prefix-icon="el-icon-search" class="hidden-sm-and-down" size="small"></el-input>
                                <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
                                <el-button type="success" @click="add" size="small">添加</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                          v-loading="load"
                          size="small"
                          element-loading-spinner="el-icon-loading"
                          element-loading-text="加载中..."
                          :default-sort="{ prop: 'AddDTime', order: 'descending' }">
                    <el-table-column label="品牌名称" min-width="200" prop="Brand"></el-table-column>
                    <el-table-column label="创建时间" width="200" prop="AddDTime" :formatter="Getdate" sortable></el-table-column>
                    <el-table-column label="更新时间" width="200" prop="UpdateDTime" :formatter="Getdate" sortable></el-table-column>
                    <el-table-column label="备注" min-width="200" prop="Remark"></el-table-column>
                    <el-table-column label="状态" width="200">
                        <template #default="scope">
                            <span v-if="scope.row.Status == 0"><span style="color:red">停用</span></span>
                            <span v-if="scope.row.Status == 1">启用</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="270">
                        <template #default="scope">
                            <el-button v-if="scope.row.Status == 0" type="primary" @click="Enable(scope.row.ID)" style="margin-right:20px;">启用</el-button>
                            <el-button v-if="scope.row.Status == 1" type="primary" @click="Disable(scope.row.ID)" style="margin-right:20px;">停用</el-button>
                            <el-button type="primary" @click="Edit(scope.row.ID)" :disabled="scope.row.Status == 0 ? false : true" style="margin-right:20px;">
                                编辑</el-button>
                            <el-button type="primary" @click="Del(scope.row.ID)" :disabled="scope.row.Status == 0 ? false : true">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
    <el-dialog v-model="editor" title="编辑品牌信息" width="30%">
        <el-form :model="brandinfo" label-position="left" :rules="rule" ref="brandinfo">
            <el-form-item label="品牌名称" label-width="100px" prop="brandname">
                <el-input v-model="brandinfo.brandname"></el-input>
            </el-form-item>
            <el-form-item label="备注" label-width="100px">
                <el-input type="textarea" rows="4" v-model="brandinfo.remark"></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="CloseDialog('brandinfo')">取 消</el-button>
                <el-button type="primary" @click="submitform('brandinfo')">确 定</el-button>
            </span>
        </template>
    </el-dialog>
</template>
<style scoped>
.searchblock {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
</style>
<script>
import constant from "@/constant"
import { ElMessage } from 'element-plus'
export default {
    data() {
        return {
            curpage: 1,
            pagesize: 10,
            totalcount: '',
            keyword: '',
            status: '0',
            tabledata: [],
            statuslabel: '停用',
            editor: false,
            brandinfo: {
                brandname: '',
                remark: '',
                id: '',
                status: '0'
            },
            rule: {
                brandname: [{
                    required: true, message: '请输入品牌名称', trigger: 'blur'
                }]
            }
        }
    },
    methods: {
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        CloseDialog(formName) {
            this.editor = false;
            this.$refs[formName].resetFields();
        },
        add() {
            this.brandinfo.brandname = '';
            this.brandinfo.remark = '';
            this.brandinfo.id = '';
            this.editor = true;
        },
        submitform(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.post(constant.save_brand_url, this.brandinfo, {
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                    }).then((response) => {
                        console.log(response.data);
                        const cur_obj = this;
                        if (response.data == "OK") {
                            cur_obj.editor = false;
                            ElMessage.success({
                                type: 'success',
                                message: '操作成功',
                                duration: 1000,
                                onClose: function () {
                                    cur_obj.$router.go(0);
                                }
                            });
                        } else {
                            ElMessage.error({
                                type: 'error',
                                message: '操作失败',
                                duration: 1500,
                            });
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        ChangeLabel() {
            if (this.brandinfo.status == "0")
                this.statuslabel = '停用';
            if (this.brandinfo.status == "1")
                this.statuslabel = '启用';
        },
        Getdate(row, column) {
            var cur_date = row[column.property];
            console.log(cur_date);
            return formatDate(cur_date);
            function formatDate(date) {
                var datetime = new Date(date * 1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                return year + "-" + month + "-" + day;
            }
        },
        Edit(id) {
            if (!id)
                return false;
            console.log(id);
            this.axios.get(constant.get_brand_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response)
                this.editor = true;
                this.brandinfo.brandname = response.data.Brand;
                this.brandinfo.id = response.data.ID;
                this.brandinfo.remark = response.data.Remark;
                this.brandinfo.status = response.data.Status;
            });
        },
        Enable(id) {
            this.axios.get(constant.enable_brand_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response);
                if (response.data == "OK") {
                    this.$router.go(0);
                } else {
                    ElMessage.error({
                        type: 'error',
                        message: '操作失败'
                    });
                }
            });
            return false;
        },
        Disable(id) {
            this.axios.get(constant.disable_brand_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response);
                if (response.data == "OK") {
                    this.$router.go(0);
                } else if (response.data == "HAVEMODEL") {
                    ElMessage.error({
                        type: 'error',
                        message: '存在属于该品牌的型号，不可停用'
                    });
                } else if (response.data == 'HAVEPDT') {
                    ElMessage.error({
                        type: 'error',
                        message: '存在属于该品牌的商品，不可停用'
                    });
                } else {
                    ElMessage.error({
                        type: 'error',
                        message: '操作失败'
                    });
                }
            });
            return false;
        },
        Del(id) {
            if (!confirm("此操作将删除该品牌, 是否继续?"))
                return false;
            this.axios.get(constant.del_brand_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                const cur_obj = this;
                if (response.data == "OK") {
                    ElMessage.success({
                        type: 'success',
                        message: '删除成功',
                        duration: 1000,
                        onClose: function () {
                            cur_obj.$router.go(0);
                        }
                    });
                } else if (response.data == "ISENABLE") {
                    ElMessage.error({
                        type: 'error',
                        message: '删除失败，请停用该品牌，并确认该品牌下没有产品再进行删除操作'
                    });
                }
            });
        },
        init() {
            console.log(this.status);
            this.axios.get(constant.get_brand_url, {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    status: this.status,
                    keyword: this.keyword,
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                }
            }).then((response) => {
                console.log(response);
                this.tabledata = response.data;
            });
        }
    },
    created: function () {
        this.axios.get(constant.get_brand_url, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                curpage: this.curpage,
                pagesize: this.pagesize,
            }
        }).then((response) => {
            console.log(response.data);
            this.tabledata = response.data;
        });
    }
}
</script>